
.social-media{
  padding: 15px 30px;
  height: 100%;
  width: 100%;
  display: flex;
  font-family: 'Montserrat', sans-serif;
  display: block;
  box-shadow: 0 6px 9px 0px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  text-transform: uppercase;
  text-decoration: none;
  transition: all 0.3s;
  font-weight: bold;
  border: none;
}

.facebook-connect-button{
  background:#207AC6;
  color:#fff;
  width: auto;
  margin: 0 auto;
}

.facebook-connect-button i{
  margin-right:20px;
}

.insta-connect-button{
  background-color: #E33E5C;
  color: #FFF;
}

.twitter-connect-button{
    background:#1da1f2;
    color:#fff;
}
.border-box{
      border: 1px solid #dadada;
    margin: 24px 50px;
    padding: 50px;
    border-radius:5px;
}
.connect-button{
  
    display: inline-block;
    width: auto;
    padding: 20px 20px;
    color: #fff;
    background: #4CAF50;
    height:auto;

}
.tooltip-custom {
    position: relative;
    display: inline-block;
  }
  .tooltip-custom .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip-custom .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip-custom:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

.social-media-icon{
    position: absolute;
  left: 10px;
  background: #2bc4ff;
  padding: 5px;
  color:#fff;
  border-radius: 3px 0 3px 0px;
  font-size:20px;
  width:30px;
  text-align:center
}

.card{
  border: none !important;
}

.card-header {
  padding: .75rem 1.25rem;
  margin-bottom: 0;
  background-color: #f0f3f5;
  border-bottom: 1px solid #c8ced3;
}

.card-content{
      max-width: 540px;
    border: 1px solid #d8d8d8!important;
    border-radius: 5px;
    padding: 5px 10px;
    margin-bottom: 10px!important;
}

.facebook-bg{
  background: #395793;
}
.social-card{
  padding:10px;
}

.m-b0{
  margin-bottom:0px;
}
.btn-view{
  position:absolute;
  right:10px;
  top:10px;
}
.eq-col .card{
  height:100%;
  margin:0
}
.text-ellipsis{
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block
}

.instragm-bg{
  background: #f09433; 
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}


.facebook-page-list{
  display: inline-block;
  margin-left: 10px
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border-top: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: #20a8d8;
    border: 1px solid #20a8d8;
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin:2px;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #fff;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
.react-tags__selected-tag-name{
  text-transform: uppercase;

}
.tags-style, .tags-style:focus, .tags-style:hover{
  outline:none;
  box-shadow:none;
  margin:8px 5px;
  min-width:120px;
}
.code-generate-wrapper{
      position: relative;
    width: 300px;
    font-size:24px;
    margin:0 auto;
}
.code-generate-wrapper input{
      width: 100%;
      padding:10px 16px;
}
.code-generate-wrapper span{
         position: absolute;
    right: 1px;
    top: 1px;
    background: #dedede;
    padding: 11px 16px;
    bottom: 1px;
}