// Here you can add other styles

.m-t10{
  margin-top: 10px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-animation: autofill 0s forwards;
  animation: autofill 0s forwards;
}

@keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

@-webkit-keyframes autofill {
  100% {
    background: transparent;
    color: inherit;
  }
}

.loading{	width: 100%;    height: 100%;    z-index: 9999;    position: fixed;    background: #FFF;    opacity: 0.7;}
.minW-160{min-width: 160px;}
.inline-block{display: inline-block;}
#autocomplete-input + div{
  z-index: 9
}

img{ max-width: 100%;}
.thumbimg{ width: 70px;}
.drop-ellipses{ width: 20px;}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #1FB264;
  position: relative;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.react-input-emoji--container{
  margin-left: 0 !important;
}

.area-height{
  height: 60px;
}


.toast{ display: none}
.gate6-footer{ display: none}

.logo-title{ font-size: 20px; font-weight: 700; font-weight: 700; padding-left: 20px;}
.app-header .nav-item .nav-link > .img-avatar, .app-header .nav-item .avatar.nav-link > img{border: 1px solid #dedede;}
.nav-item .form-check-input{
  position: inherit;
  margin: 0 5px;
}
.box-content{
	background-color: #fff;
}
.justify-content-left{
  justify-content:flex-start
}

.justify-content-right{
  justify-content:flex-end
}
// Here you can add other styles
.sidebar{
  background: #fff;
  color: #000;
}
.sidebar .nav-title{
  color: #b2b4b5
}
.sidebar .nav-link{
  color: #73818f;
}
.sidebar .nav-link.active, .sidebar .nav-link.active .nav-icon{
  color: #fff !important;
  background: #20a8d8;
}
.sidebar .nav-link:hover{
  color: #fff !important;
}
.sidebar .nav-dropdown.open{
  background: #fff;
  color: #73818f;
}
.sidebar .nav-dropdown.open .nav-link{
  color: #73818f;
}
.sidebar .nav-dropdown.open > .nav-dropdown-items{
  margin-left: 15px;
}

.popover-body label{
  display: block;
  width: 100px;
  margin-left: 30px
}

.pagination-block-right > ul{
  float: right;
}


.login-page .card{ border: 0; border-radius: 5px;}
.loginWrapper{ border: none !important; background: none;}
.loginWrapper .form-group{ position: relative; margin-bottom: 30px !important;}
.loginWrapper .form-group label{ position: absolute;  top: -10px;  left: 20px;  margin: 0;  background: #fff;  padding: 0 10px; color: #929292}
.loginWrapper input, .loginWrapper input:focus, .loginWrapper input:active, .loginWrapper input:visited, .loginWrapper input:focus-within{ border: solid 1px #93EDDE !important; background: none !important; height:inherit !important; padding: 20px 12px 15px 12px !important}
.loginWrapper .btn{ width: 100%; background: #93EDDE !important; color: #fff; padding-top: 10px !important; padding-bottom: 10px !important; font-weight: 700 !important; font-size: 16px !important; }


.chartbox{ width: 100%; height: 3500px; border: 0; }

.sidebar{ background: #207AC6; top: 0;  z-index: 9999 !important;  height: 100vh !important;}

.sidebar .nav-link{ color: #fff}
.sidebar .nav-link.active, .sidebar .nav-link.active .nav-icon, .sidebar .nav-link:hover{ background:#1F75BE }
.sidebar a.nav-link  img{ margin-right: 10px;}

.sidebar-logo{ text-align: center; padding: 2rem 0 3rem 0; position: relative;}
.sidebar-logo img{ max-width: 125px;}

.sidebar-logo .navbar-toggler-icon{background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%2373818f' stroke-width='2.25' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");}

.sidebar-logo button{ position: absolute; right: 0;}
.title-img img{ width: 70px; height: 70px; margin: 0 3px;}
.title-bg-color{ height: 25px; width: 100px; -webkit-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.5);
  -moz-box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.5);
  box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.5);}

  .validation-error {
    padding: 4px;
    color: #D5563B;
    font-size: 13px;
  }

  .feedback-popup h6{ margin: 0; padding: 0}

  .content-multi-select {height: 200px !important;margin-bottom: 20px;}
  .image-tagline {font-size: 17px;font-weight: 100; color: #73818f !important;}

  .inline-social-icons { width: 30px};

  .add-edit-thumb {background: #efefef; text-align: center; padding: 5px; margin: 10px 0 0; box-shadow: inset 0px 0px 10px rgba(0,0,0,0.1); border-radius: 5px; margin-bottom: 1rem}
  .add-edit-thumb img{max-height: 150px;}

  .modal-lg{max-width: 1100px;}
  .col-lr-bdr{border-left: 1px solid #e5e5e5; border-right: 1px solid #e5e5e5}
  .col-narrow{ padding: 0 9px!important; flex-grow: 0.7;}
  .resolved-by{color: #207AC6; font-size: 20px; text-transform: capitalize;}
  .add-settings{right: 0px; float: right;}
  .sidebar {
    .scrollbar-container{
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .multi-select{height: 220px !important;}
  .copied-url{position: relative;}
  .url-col{
    justify-content: center;
    align-items: baseline;
    flex-direction: column;
    display: flex;
    padding: 10px 0
  }
  .copied-url:before{    position: absolute;
    width: 100%;
    height: 100%;
    content: "Copied";
    background-color: rgba(32,122,198,0.7);
    background-image: none;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    transition: .5s;
    left: 0;
    padding: 10px;
    color: #fff;
    font-weight: 400;
    font-size: 16px;
    z-index: 1;
    text-align:center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }

  table .table-head {background: #d2d2d2;}

  table .no-data-found {width: 100%;right: 50%;text-align: center;padding: 50;}
  .commission-table{
    min-width: 342px;
    margin: 0px auto;
    display: inline-block;
    text-align: left;
  }
  .commission-table table td{
    padding-left:0;
    padding-right:0;
  }
  .link{
    color: #167495;
    text-decoration: underline;
    cursor: pointer;
  }
  .pointer{
    cursor: pointer;
  }
  .withdraw-commission{
    background: #efefef;
    padding: 10px 10px 0;
  }
  .tooltip-custom {
    position: relative;
    display: inline-block;
  }

  .tooltip-custom .tooltiptext {
    visibility: hidden;
    width: 140px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -75px;
    opacity: 0;
    transition: opacity 0.3s;
  }

  .tooltip-custom .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }

  .tooltip-custom:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
  .switch-inline {margin-top: 7px;}
  .long-switch{
    width: 113px;
    margin-top: 6px;
  }
  .long-switch .switch-input:checked ~ .switch-slider::before {
      transform: translateX(74px);
  }
  .switch-label .switch-slider::after{
      left: 25px;
      margin-top: -.7em;
  }

  .react-datetime-picker__wrapper{
    border: none!important
  }
  .react-date-picker__wrapper {
    display: flex;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    margin-left: 5px;
  }
  .react-date-picker__calendar {
    width: 350px;
    max-width: 100vw;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 5;
  }
  .react-date-picker__wrapper > .react-date-picker__calendar-button{
    background: url(/static/media/calendar-icon.67772324.svg) center center no-repeat;
    width: 30px;
    height: 23px;
    background-size: 88%;
    background-position: 0 4px;

  }
  .react-date-picker__wrapper > .react-date-picker__calendar-button:before{
    content: '';
  }
  .select-language{
    background: #efefef;
    padding-top: 10px;
    margin:-1rem -15px 10px;
  }
  .select-language > label{
    font-weight: bold;
    padding-left: 10px;
  }


  .trophy-img-wrap {
    width: 100%;
    height: 175px;
    background: #efefef;
    float: left;
    padding: 10px;
    margin: 10px 0 0;
    text-align: center;
    border-radius: 10px;
  }


  .trophy-img-wrap img {
    max-width: 100%;
    max-height: 110px;
    margin-bottom: 10px
  }
  .remove-trophy{
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .placeholder-trophy{
    height: 115px;
  }

  .align-validation-text  {
    margin: 11px 0 0 0;
  }

  .dataTable-wrapper .text-sm {
    font-size: 14px;
  }

  .social-media{
    padding: 15px 30px;
    height: 100%;
    width: 100%;
    display: flex;
    font-family: 'Montserrat', sans-serif;
    display: block;
    box-shadow: 0 6px 9px 0px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    text-transform: uppercase;
    text-decoration: none;
    transition: all 0.3s;
    font-weight: bold;
    border: none;
  }

  .facebook-connect-button{
    background:#207AC6;
    color:#fff;
  }
  .facebook-connect-button i{
    margin-right:20px;
  }

  .insta-connect-button{
    background-color: #E33E5C;
    color: #FFF;
  }

  .twitter-connect-button{
      background:#1da1f2;
      color:#fff;
  }

  .social-media-icon{
      position: absolute;
    left: 0;
    background: #2bc4ff;
    padding: 5px;
    color:#fff;
    border-radius: 3px 0 3px 0px;
    font-size:20px;
    width:30px;
text-align:center
  }

  .facebook-bg{
  background: #395793;
  }
.social-card{
  padding:10px;
}

.m-b0{
  margin-bottom:0px;
}
.btn-view{
  position:absolute;
  right:10px;
  top:10px;
}
.eq-col .card{
  height:100%;
  margin:0
}

.text-ellipsis{
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    display: block
}

.instragm-bg{
  background: #f09433;
  background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
  background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}


.facebook-page-list{
  display: inline-block;
  margin-left: 10px
}

.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border-top: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
    font-weight: 400;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    background-color: #20a8d8;
    border: 1px solid #20a8d8;
    padding: 0.375rem 0.75rem;
    font-size: 0.675rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    margin:2px;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #fff;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}
.react-multi-select button{
    width: 100%;
    height: calc(1.5em + 0.75rem + 5px);
    padding: 0.375rem 0.85rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e4e7ea;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    box-shadow: none;

}
.react-multi-select button+div{
  width: 100%;
    border: 1px solid #7B9CD3;
    margin: 0!important;
    border-radius: 0!important;
}
.react-multi-select [class$="-group"],
.react-multi-select [class*="-group "]{
  font-size: 0.875rem;
  color: #5c6873;
  font-weight: normal;
  text-transform: capitalize;
}
@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}
.react-tags__selected-tag-name{
  text-transform: uppercase;

}
.tags-style, .tags-style:focus, .tags-style:hover{
  outline:none;
  box-shadow:none;
  margin:8px 5px;
  min-width:120px;
}
.selected-email-box{
  background: #efefef;
  padding: 10px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 300px;
}
.selected-emails{
  border:1px solid #333;
  border-radius:50px;
  padding:5px 10px;
  display:inline-block;
  margin:5px;
}
.selected-emails span{
  padding-right:10px;
}

.action-btns {
  padding : 10px;
}

.no-record-label{
  text-align: center;
}


.trending-content-row-wrapper{
  height: 30%;
}
.trending-content-row{
  height: 85%;
}

/* Smartphones (portrait and landscape) */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .p-4 {
    padding: 0.5rem !important;
  }
}
/* iPhone 4  */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .p-4 {
    padding: 0.5rem !important;
  }
}
