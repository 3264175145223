/* Smartphones (portrait and landscape) */
@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .p-4 {
    padding: 0.5rem !important;
  }
}
/* iPhone 4  */
@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
  only screen and (min-device-pixel-ratio: 1.5) {
  .p-4 {
    padding: 0.5rem !important;
  }
}
/* sticky table Inspect */
.sticky-table-table {
  width: 100% !important;
}
.sticky-table-row {
  width: 100% !important;
}
.pagination {
  margin-bottom: 0px !important;
  float: right;
  margin-right: 10px;
}
.pagination .previous,
.pagination .next {
  text-align: center;
  vertical-align: middle;
  padding: 5px;
}
.nav-item {
  z-index: 0;
}
.pointer strong {
  margin-right: 4px;
}
